import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  filterParametersSelector,
  reportFilterCountryCodeSelector,
  reportPendingInterfacesSalesSelector,
} from '../../state';
import { PendingInterfaces } from '../../types';
import handlerFactory, { PendingInterfacesDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<PendingInterfaces, PendingInterfacesDataGridContext>();

  const { t } = useTranslation();
  const title = t('pending-interfaces.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const salesResource = useSelector(reportPendingInterfacesSalesSelector);
  const { data, loading } = salesResource;

  const appParameters = useSelector(filterParametersSelector);

  const gridContext = useMemo(
    () => ({
      t,
      country,
    }),
    [t, country]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);

  return null;
};

const PendingInterfacesDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default PendingInterfacesDataGrid;
