import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  reportCancellationsFilterGroupBySelector,
  reportCancellationsFilterSelector,
  reportCancellationsSelector,
  reportFilterCountryCodeSelector,
} from '../../state';
import { Cancellation } from '../../types';
import handlerFactory, { CancellationDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<Cancellation, CancellationDataGridContext>();

  const { t } = useTranslation();
  const title = t('cancellations.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const cancellationsResource = useSelector(reportCancellationsSelector);
  const groupBy = useSelector(reportCancellationsFilterGroupBySelector);
  const reportCancellationsFilters = useSelector(reportCancellationsFilterSelector);

  const { data, loading } = cancellationsResource;

  const gridContext = useMemo(
    () => ({
      t,
      country,
      groupBy,
    }),
    [t, country, groupBy]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);
  handler.setFilters(reportCancellationsFilters);

  return null;
};

const CancellationsDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default CancellationsDataGrid;
