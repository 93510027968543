import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeClosingFilterFederativeEntities,
  reportClosingFilterFederativeEntitySelector,
} from '../../../state';
import { FederativeEntitySelect, SimpleSelectProps } from '../../selects';

type FederativeEntityFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const FederativeEntityFilter = (props: FederativeEntityFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const federativeEntities = useSelector(reportClosingFilterFederativeEntitySelector);

  const handleChange = useCallback(
    (value: string[]) => dispatch(changeClosingFilterFederativeEntities(value)),
    [dispatch]
  );

  return (
    <FederativeEntitySelect
      {...props}
      label={t('toolbar.labels.federativeEntity')}
      value={federativeEntities}
      onChange={handleChange}
      selectAll
    />
  );
};

export default FederativeEntityFilter;
