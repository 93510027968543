import { useSelector } from 'react-redux';
import { AppState } from '../state';
import { FilterApplied } from '../types';

type useFilterAppliedProps = FilterApplied;

const useFilterAppliedLength = (
  filters: useFilterAppliedProps,
  selector: (state: AppState) => FilterApplied
): number => {
  const filterApplied = useSelector(selector);

  return filterApplied.filter(i => filters.includes(i)).length;
};

export default useFilterAppliedLength;
