import Grid from '@mui/material/Grid';
import {
  findPettyCashDifferenceRequested,
  reportPettyCashDifferenceLoadingSelector,
} from '../state';

import {
  CountryFilter,
  DifferenceTypeFilter,
  ReportFilterActions,
  ReportFilters,
  StoreFilter,
} from './ReportFilters';

import { AppPermissions } from '../types';
import { BusinessDateRangeFilter } from './CollectionsFilters';
import { PermissionsProvider } from './Permissions';

const onedriveAction = () => findPettyCashDifferenceRequested(true);

const PettyCashDifferenceSearch = () => (
  <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
    <ReportFilters
      loadingSelector={reportPettyCashDifferenceLoadingSelector}
      applyAction={findPettyCashDifferenceRequested}
      onedriveAction={onedriveAction}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={3} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={4}>
          <BusinessDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <DifferenceTypeFilter fullWidth />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </ReportFilters>
  </PermissionsProvider>
);

export default PettyCashDifferenceSearch;
