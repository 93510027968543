import Grid from '@mui/material/Grid';
import {
  changeFilterDiscountsJoin,
  findGCsByProductRequested,
  reportFilterDiscountsJoinSelector,
  reportGCsByProductLoadingSelector,
  reportGcsFilterFilterAppliedSelector,
} from '../state';

import {
  CountryFilter,
  HourRangeFilter,
  PluFilter,
  PosFilter,
  ReportFilterActions,
  ReportFilters,
  SalesTypesFilter,
  SegmentsFilter,
  StoreFilter,
} from './ReportFilters';

import { AppPermissions } from '../types';
import {
  AccountingDateRangeFilter,
  BusinessDateRangeFilter,
  OperationDateRangeFilter,
} from './GcsFilters';
import { PermissionsProvider } from './Permissions';
import { DialogFiltersProps, DiscountsProps } from './ReportFilters/ReportFiltersProvider';

const dialogFiltersConfig: DialogFiltersProps = {
  options: ['startOperationDate', 'endOperationDate', 'startAccountingDate', 'endAccountingDate'],
  selector: reportGcsFilterFilterAppliedSelector,
  filters: (
    <>
      <OperationDateRangeFilter />
      <AccountingDateRangeFilter />
    </>
  ),
};

const onedriveAction = () => findGCsByProductRequested(true);

const discountsConfig: DiscountsProps = {
  options: {
    sale: false,
    item: false,
  },
  action: changeFilterDiscountsJoin,
  selector: reportFilterDiscountsJoinSelector,
  showJoins: true,
};

const GCsByProductSearch = () => (
  <ReportFilters
    dialogFiltersConfig={dialogFiltersConfig}
    loadingSelector={reportGCsByProductLoadingSelector}
    applyAction={findGCsByProductRequested}
    onedriveAction={onedriveAction}
    discountsConfig={discountsConfig}
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PosFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={4} xl={2}>
          <SalesTypesFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <SegmentsFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <HourRangeFilter />
        </Grid>
        <Grid item xs={12} md={5} lg={4} xl={3}>
          <BusinessDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <PluFilter />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default GCsByProductSearch;
