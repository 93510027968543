import FederativeEntitySimpleSelect, {
  FederativeEntitySimpleSelectProps,
} from './FederativeEntitySimpleSelect';
import FederativeEntityMultiSelect, {
  FederativeEntityMultiSelectProps,
} from './FederativeEntityMultiSelect';

type FederativeEntitySelectProps =
  | (FederativeEntityMultiSelectProps & { simple?: false })
  | (FederativeEntitySimpleSelectProps & { simple: true });

const FederativeEntitySelect = (props: FederativeEntitySelectProps) => {
  const { simple, ...others } = props;
  if (simple) {
    return <FederativeEntitySimpleSelect {...(others as FederativeEntitySimpleSelectProps)} />;
  }
  return <FederativeEntityMultiSelect {...(others as FederativeEntityMultiSelectProps)} />;
};

export default FederativeEntitySelect;
