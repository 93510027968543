import Grid from '@mui/material/Grid';
import {
  changeSaleProductFilterGroupBy,
  findSalesByProductRequested,
  reportFilterAppliedSelector,
  reportFilterSalesProductGroupBySelector,
  reportSalesByProductLoadingSelector,
} from '../state';

import {
  AccountingDateRangeFilter,
  BusinessDateRangeFilter,
  CountryFilter,
  HourRangeFilter,
  OperationDateRangeFilter,
  PluFilter,
  PosFilter,
  ReportFilterActions,
  ReportFilters,
  ReportFiltersProps,
  SalesTypesFilter,
  SegmentsFilter,
  StoreFilter,
  TimeBandFilter,
} from './ReportFilters';

import { AppPermissions, ReportFilterGroupBy } from '../types';
import { PermissionsProvider } from './Permissions';

const groupByConfig: ReportFiltersProps<ReportFilterGroupBy>['groupByConfig'] = {
  options: {
    countryAcronym: true,
    storeAcronym: false,
    businessDate: false,
    accountingDate: false,
    operationDate: false,
    posType: false,
    saleType: false,
    posNum: false,
    timeRange: false,
    timeBand: false,
    plu: false,
  },
  action: changeSaleProductFilterGroupBy,
  selector: reportFilterSalesProductGroupBySelector,
};

const dialogFiltersConfig: ReportFiltersProps<ReportFilterGroupBy>['dialogFiltersConfig'] = {
  options: ['startOperationDate', 'endOperationDate', 'startBusinessDate', 'endBusinessDate'],
  selector: reportFilterAppliedSelector,
  filters: (
    <>
      <BusinessDateRangeFilter />
      <OperationDateRangeFilter />
    </>
  ),
};

const onedriveAction = () => findSalesByProductRequested(true);

const SalesByProductSearch = () => (
  <ReportFilters
    groupByConfig={groupByConfig}
    dialogFiltersConfig={dialogFiltersConfig}
    loadingSelector={reportSalesByProductLoadingSelector}
    applyAction={findSalesByProductRequested}
    onedriveAction={onedriveAction}
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PosFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={6} xl={2}>
          <SalesTypesFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <SegmentsFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <TimeBandFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <HourRangeFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AccountingDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PluFilter />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default SalesByProductSearch;
