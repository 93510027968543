import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePendingInterfacesFilterCountry,
  reportPendingInterfacesFilterCountrySelector,
} from '../../../state';
import { CountrySelect, SimpleSelectProps } from '../../selects';

type CountryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const CountryFilter = (props: CountryFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const country = useSelector(reportPendingInterfacesFilterCountrySelector);

  const handleChange = useCallback(
    (value: string) => dispatch(changePendingInterfacesFilterCountry(value)),
    [dispatch]
  );

  return (
    <CountrySelect
      {...props}
      label={t('toolbar.labels.country')}
      value={country}
      onChange={handleChange}
      propId="countryCode"
      simple
    />
  );
};

export default CountryFilter;
