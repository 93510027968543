import Grid from '@mui/material/Grid';
import {
  changeFilterThirdPartySalesByItemsGroupBy,
  findThirdPartySalesByItemsRequested,
  reportFilterThirdPartySalesByItemsGroupBySelector,
  reportThirdPartySalesByItemsLoadingSelector,
} from '../state';

import {
  AccountingDateRangeFilter,
  CountryFilter,
  PluFilter,
  ReportFilterActions,
  ReportFilters,
  ReportFiltersProps,
  StoreFilter,
} from './ReportFilters';

import { AppPermissions, ReportThirdPartySalesByItemsGroupBy } from '../types';
import { PermissionsProvider } from './Permissions';

const onedriveAction = () => findThirdPartySalesByItemsRequested(true);

const groupByConfig: ReportFiltersProps<ReportThirdPartySalesByItemsGroupBy>['groupByConfig'] = {
  options: {
    countryAcronym: true,
    storeAcronym: false,
    accountingDate: false,
    plu: false,
  },
  action: changeFilterThirdPartySalesByItemsGroupBy,
  selector: reportFilterThirdPartySalesByItemsGroupBySelector,
};

const ThirdPartySalesByItemsSearch = () => (
  <ReportFilters
    loadingSelector={reportThirdPartySalesByItemsLoadingSelector}
    applyAction={findThirdPartySalesByItemsRequested}
    onedriveAction={onedriveAction}
    groupByConfig={groupByConfig}
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={3} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={3}>
          <AccountingDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <PluFilter />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default ThirdPartySalesByItemsSearch;
