import { FunctionComponent } from 'react';
import {
  Dashboard,
  Gcs,
  GcsPaymentTypes,
  Help,
  Profile,
  SaleTotals,
  SignIn,
  Http403,
  Http404,
  Reprocess,
  ReprocessNew,
  Closing,
  MissingClosingCauses,
  AccountingEntries,
  AccountingEntriesNew,
  CompanyCodes,
  CompanyCodeEdit,
  AccountingParameters,
  AccountingParametersEdit,
  MonthClosing,
  MonthClosingNew,
  ReportTender,
  Reliefs,
  Cancellations,
  OakBrook,
  DashboardClosing,
  SalesByProduct,
  GCsByProduct,
  ThirdPartySalesByItems,
  DashboardMastersStatus,
  PettyCashDifference,
  PettyCashDifferenceByPaymentType,
  SalesJournal,
  MastersImport,
  PendingInterfaces,
} from '../pages';
import { AppPermissions } from '../types';

export interface RouteConfig {
  key: string;
  path: string;
  component: FunctionComponent;
  modeModal: boolean;
}

interface ProtectedRouteConfig extends RouteConfig {
  permissions: AppPermissions[];
  defaultRedirectForPermission?: AppPermissions;
}

const getRouteConfig = (
  key: string,
  path: string,
  component: FunctionComponent,
  modeModal = false
): RouteConfig => ({
  key,
  path,
  component,
  modeModal,
});

const getProtectedRouteConfig = (
  key: string,
  path: string,
  component: FunctionComponent,
  defaultRedirectForPermission: AppPermissions | undefined = undefined,
  modeModal = false,
  permissions: AppPermissions[] = [AppPermissions.SALES_TOTALS]
): ProtectedRouteConfig => ({
  ...getRouteConfig(key, path, component, modeModal),
  permissions,
  defaultRedirectForPermission,
});

const protectedRoutes = {
  saleTotals: getProtectedRouteConfig('sale-totals', '/sale-totals', SaleTotals, undefined, false, [
    AppPermissions.SALES_TOTALS,
  ]),
  salesByProduct: getProtectedRouteConfig(
    'sales-product',
    '/sales-product',
    SalesByProduct,
    undefined,
    false,
    [AppPermissions.SALES_TOTALS]
  ),
  gcsByProduct: getProtectedRouteConfig(
    'gcs-product',
    '/gcs-product',
    GCsByProduct,
    undefined,
    false,
    [AppPermissions.SALES_TOTALS]
  ),
  thirdPartySalesByItems: getProtectedRouteConfig(
    'third-party-sales-by-items',
    '/third-party-sales-by-items',
    ThirdPartySalesByItems,
    undefined,
    false,
    [AppPermissions.SALES_TOTALS]
  ),
  reportTender: getProtectedRouteConfig(
    'report-tender',
    '/report-tender',
    ReportTender,
    undefined,
    false,
    [AppPermissions.TENDER]
  ),
  reliefs: getProtectedRouteConfig('reliefs', '/reliefs', Reliefs, undefined, false, [
    AppPermissions.RELIEFS,
  ]),
  cancellations: getProtectedRouteConfig(
    'cancellations',
    '/cancellations',
    Cancellations,
    undefined,
    false,
    [AppPermissions.CANCELLATIONS]
  ),
  gcs: getProtectedRouteConfig('gcs', '/gcs', Gcs, undefined, false, [AppPermissions.GCS]),
  gcsPaymentTypes: getProtectedRouteConfig(
    'gcs-payment-types',
    '/gcs-payment-types',
    GcsPaymentTypes,
    undefined,
    false,
    [AppPermissions.GCS]
  ),
  profile: getProtectedRouteConfig('profile', '/profile', Profile, undefined, true),
  help: getProtectedRouteConfig('help', '/help', Help, undefined, true),
  oakBrook: getProtectedRouteConfig('oak-brook', '/oak-brook', OakBrook, undefined, false, [
    AppPermissions.OAK_BROOK_SALES,
  ]),
  pettyCashDifference: getProtectedRouteConfig(
    'petty-cash-difference',
    '/petty-cash-difference',
    PettyCashDifference,
    undefined,
    false,
    [AppPermissions.SALES_TOTALS]
  ),
  PettyCashDifferenceByPaymentType: getProtectedRouteConfig(
    'petty-cash-difference-by-payment-type',
    '/petty-cash-difference-by-payment-type',
    PettyCashDifferenceByPaymentType,
    undefined,
    false,
    [AppPermissions.SALES_TOTALS]
  ),
  salesJournal: getProtectedRouteConfig(
    'sales-journal',
    '/sales-journal',
    SalesJournal,
    undefined,
    false,
    [AppPermissions.SALES_TOTALS]
  ),
  mastersImport: getProtectedRouteConfig(
    'masters-import',
    '/masters-import',
    MastersImport,
    undefined,
    false,
    [AppPermissions.DASHBOARD_MASTER_IMPORT]
  ),
  pendingInterfaces: getProtectedRouteConfig(
    'pending-interfaces',
    '/pending-interfaces',
    PendingInterfaces,
    undefined,
    false,
    [AppPermissions.SALES_TOTALS]
  ),
};

const protectedNestedRoutes = {
  dashboard: {
    index: getProtectedRouteConfig(
      'dashboard',
      '/dashboard',
      Dashboard,
      AppPermissions.SALES_TOTALS,
      false,
      [
        AppPermissions.DASHBOARD_SALES_GCS,
        AppPermissions.DASHBOARD_DAILY_SALES,
        AppPermissions.DASHBOARD_MISSINGS,
        AppPermissions.DASHBOARD_MONTH_CLOSING,
      ]
    ),
    children: {
      dashboardMissingClosingDetail: getProtectedRouteConfig(
        'dashboardClosing',
        'closing',
        DashboardClosing,
        undefined,
        false,
        [AppPermissions.MISSING_CLOSING_DETAIL]
      ),
      dashboardMastersStatus: getProtectedRouteConfig(
        'dashboardMastersStatus',
        'masters-status',
        DashboardMastersStatus,
        undefined,
        false,
        [AppPermissions.MISSING_CLOSING_DETAIL]
      ),
    },
  },
  reprocess: {
    index: getProtectedRouteConfig('reprocess', '/reprocess', Reprocess, undefined, false, [
      AppPermissions.REPROCESS,
    ]),
    children: {
      create: getProtectedRouteConfig('reprocessNew', 'new', ReprocessNew, undefined, false, [
        AppPermissions.REPROCESS,
      ]),
    },
  },
  missingClosingDetail: {
    index: getProtectedRouteConfig(
      'missing-closing-detail',
      '/missing-closing-detail',
      Closing,
      undefined,
      false,
      [AppPermissions.MISSING_CLOSING_DETAIL]
    ),
    children: {
      create: getProtectedRouteConfig(
        'MissingClosingCauses',
        'causes',
        MissingClosingCauses,
        undefined,
        false,
        [AppPermissions.MISSING_CLOSING_DETAIL]
      ),
    },
  },
  companyCodes: {
    index: getProtectedRouteConfig(
      'companyCodes',
      '/company-codes',
      CompanyCodes,
      undefined,
      false,
      [AppPermissions.COMPANY_CODES_QUERY]
    ),
    children: {
      edit: getProtectedRouteConfig(
        'companyCodesEdit',
        ':countryIdCompanyId',
        CompanyCodeEdit,
        undefined,
        false,
        [AppPermissions.COMPANY_CODES]
      ),
    },
  },
  accountingParameters: {
    index: getProtectedRouteConfig(
      'accountingParameters',
      '/accounting-parameters',
      AccountingParameters,
      undefined,
      false,
      [AppPermissions.ACCOUNTING_PARAMETERS_QUERY]
    ),
    children: {
      edit: getProtectedRouteConfig(
        'accountingParametersEdit',
        ':countryIdJournalType',
        AccountingParametersEdit,
        undefined,
        false,
        [AppPermissions.ACCOUNTING_PARAMETERS]
      ),
    },
  },
  accountingEntries: {
    index: getProtectedRouteConfig(
      'accountingEntries',
      '/accounting-entries',
      AccountingEntries,
      undefined,
      false,
      [AppPermissions.ACCOUNTING_ENTRIES_COMPLETE, AppPermissions.ACCOUNTING_ENTRIES_QUERY]
    ),
    children: {
      create: getProtectedRouteConfig(
        'accountingEntriesNew',
        'new',
        AccountingEntriesNew,
        undefined,
        false,
        [AppPermissions.ACCOUNTING_ENTRIES_COMPLETE, AppPermissions.ACCOUNTING_ENTRIES_EXECUTION]
      ),
    },
  },
  monthClosing: {
    index: getProtectedRouteConfig(
      'month-closing',
      '/month-closing',
      MonthClosing,
      undefined,
      false,
      [AppPermissions.MONTH_CLOSING]
    ),
    children: {
      create: getProtectedRouteConfig('MonthClosingNew', 'new', MonthClosingNew, undefined, false, [
        AppPermissions.MONTH_CLOSING,
      ]),
    },
  },
};

const signinRoute = getRouteConfig('signin', '/signin', SignIn);

export default {
  protectedRoutes,
  protectedNestedRoutes,
  signinRoute,
  forbiddenComponent: Http403,
  notFoundComponent: Http404,
};
