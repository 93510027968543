import { GcsByPaymentTypes } from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface GcsByPaymentTypeDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
}

const initialContext: GcsByPaymentTypeDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<GcsByPaymentTypes, GcsByPaymentTypeDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    defaultHidden: true,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t }) => t('table.columns.store'),
  });

  handler.addColumn({
    accessor: 'countryId',
    type: 'string',
    title: ({ t }) => t('table.columns.country_id'),
    defaultHidden: true,
    ...commonColProps.countryId,
  });

  handler.addColumn({
    accessor: 'storeId',
    type: 'number',
    title: ({ t }) => t('table.columns.store_id'),
    defaultHidden: true,
  });

  handler.addColumn({
    accessor: 'id',
    type: 'number',
    title: ({ t }) => t('table.columns.id'),
    width: { px: 150, pt: 120 },
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_num'),
  });

  handler.addColumn({
    accessor: 'posType',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_type'),
    width: { px: 240, pt: 200 },
  });

  handler.addColumn({
    accessor: 'transactionType',
    type: 'number',
    title: ({ t }) => t('table.columns.transaction_type'),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'accountingDate',
    type: 'date',
    title: ({ t }) => t('table.columns.accounting_date'),
  });

  handler.addColumn({
    accessor: 'operationDate',
    type: 'date',
    title: ({ t }) => t('table.columns.operation_date'),
  });

  handler.addColumn({
    accessor: 'saleDescription',
    type: 'string',
    title: ({ t }) => t('table.columns.sale_description'),
  });

  handler.addColumn({
    accessor: 'seqNumber',
    type: 'number',
    title: ({ t }) => t('table.columns.seq_num'),
  });

  handler.addColumn({
    accessor: 'receiptNum',
    type: 'number',
    title: ({ t }) => t('table.columns.receipt_num'),
  });

  handler.addColumn({
    accessor: 'fiscalReceiptNum',
    type: 'number',
    title: ({ t }) => t('table.columns.fiscal_receipt_num'),
  });

  handler.addColumn({
    accessor: 'beginOfSaleTimestamp',
    type: 'dateTime',
    title: ({ t }) => t('table.columns.begin_of_sale_timestamp'),
    width: { px: 150, pt: 145 },
  });

  handler.addColumn({
    accessor: 'endOfSaleTimestamp',
    type: 'dateTime',
    title: ({ t }) => t('table.columns.end_of_sale_timestamp'),
    width: { px: 150, pt: 145 },
  });

  handler.addColumn({
    accessor: 'dayPart',
    type: 'string',
    title: ({ t }) => t('table.columns.day_part'),
  });

  handler.addColumn({
    accessor: 'transactionAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.transaction_amt'),
  });

  handler.addColumn({
    accessor: 'taxAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.tax_amt'),
  });

  handler.addColumn({
    accessor: 'netAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.net_amt'),
  });

  handler.addColumn({
    accessor: 'tipAmount',
    type: 'number',
    title: ({ t }) => t('table.columns.tipAmount'),
  });

  handler.addColumn({
    accessor: 'idOperation',
    type: 'string',
    title: ({ t }) => t('table.columns.idOperation'),
  });

  handler.addColumn({
    accessor: 'numCoupon',
    type: 'string',
    title: ({ t }) => t('table.columns.numCoupon'),
  });

  handler.addColumn({
    accessor: 'idPosnet',
    type: 'string',
    title: ({ t }) => t('table.columns.idPosnet'),
  });

  handler.addColumn({
    accessor: 'posnet',
    type: 'string',
    title: ({ t }) => t('table.columns.posnet'),
  });

  handler.addColumn({
    accessor: 'numCard',
    type: 'string',
    title: ({ t }) => t('table.columns.numCard'),
  });

  handler.addColumn({
    accessor: 'invoiceType',
    type: 'string',
    title: ({ t }) => t('table.columns.invoice_type'),
  });

  handler.addColumn({
    accessor: 'invoiceKind',
    type: 'string',
    title: ({ t }) => t('table.columns.invoice_kind'),
  });

  handler.addColumn({
    accessor: 'fiscalPosCode',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_pos_code'),
  });

  handler.addColumn({
    accessor: 'saleReceipt',
    type: 'number',
    title: ({ t }) => t('table.columns.sale_receipt'),
  });

  handler.addColumn({
    accessor: 'receiptClass',
    type: 'string',
    title: ({ t }) => t('table.columns.receipt_class'),
  });

  handler.addColumn({
    accessor: 'fiscalName1',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_name1'),
  });

  handler.addColumn({
    accessor: 'fiscalName2',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_name2'),
  });

  handler.addColumn({
    accessor: 'fiscalAddress1',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_address1'),
  });

  handler.addColumn({
    accessor: 'fiscalAddress2',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_address2'),
  });

  handler.addColumn({
    accessor: 'tenderType',
    type: 'number',
    title: ({ t }) => t('table.columns.tender_type'),
    available: ({ country }) => ['BR'].includes(country),
  });

  handler.addColumn({
    accessor: 'tenderName',
    type: 'string',
    title: ({ t }) => t('table.columns.tender_name'),
  });

  handler.addColumn({
    accessor: 'amount',
    type: 'number',
    title: ({ t }) => t('table.columns.amount'),
  });

  handler.addColumn({
    accessor: 'operationTypeName',
    type: 'string',
    title: ({ t }) => t('table.columns.operation_type_name'),
  });

  handler.addColumn({
    accessor: 'specialSaleOrderId',
    type: 'string',
    title: ({ t }) => t('table.columns.special_sale_order_id'),
  });

  handler.addColumn({
    accessor: 'specialSaleType',
    type: 'string',
    title: ({ t }) => t('table.columns.special_sale_type'),
  });

  handler.addColumn({
    accessor: 'specialSaleMethod',
    type: 'string',
    title: ({ t }) => t('table.columns.special_sale_method'),
  });

  handler.addColumn({
    accessor: 'operatorId',
    type: 'number',
    title: ({ t }) => t('table.columns.operator_id'),
  });

  handler.addColumn({
    accessor: 'managerId',
    type: 'number',
    title: ({ t }) => t('table.columns.manager_id'),
  });

  handler.addColumn({
    accessor: 'saleKey',
    type: 'string',
    title: ({ t }) => t('table.columns.sale_key'),
  });

  handler.addColumn({
    accessor: 'plName',
    type: 'string',
    title: ({ t }) => t('table.columns.pl_name'),
  });

  return handler;
};
