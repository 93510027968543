import { salesReprocessAxios } from '../config/axiosInstances';
import { ReprocessTypeCategory } from '../types';

export interface FindReprocessRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDate?: string;
  reprocessType?: string;
  status?: string;
  federativeEntity?: string;
}

export type FindReprocessResponse = {
  country_store_business_date: string;
  reprocess_type: string;
  business_date: string;
  country: string;
  creation_date: string;
  execution_date: string;
  justification: string;
  status: string;
  store: string;
  user: string;
  federative_entity?: string;
}[];

export interface FindReprocessParametersResponse {
  types: ReprocessTypeCategory[];
  statuses: string[];
  ibGroups: {
    country_id: string;
    group_id: string;
    group_name: string;
  }[];
}

export interface CreateReprocessRequest {
  countryAcronym: string;
  storeAcronym: string;
  stores: string[];
  groupIds: string[];
  businessDate: string;
  businessDateFrom: string;
  businessDateTo: string;
  reprocessType: string;
  justification: string;
  federativeEntities: string[];
  allFailed: boolean;
}

export default class {
  static async findReprocessParameters() {
    return salesReprocessAxios.get<FindReprocessParametersResponse>(`/reprocess/parameters`);
  }

  static async findReprocess(request: FindReprocessRequest) {
    return salesReprocessAxios.get<FindReprocessResponse>(`/reprocess`, {
      params: request,
    });
  }

  static async createReprocess(data: CreateReprocessRequest) {
    return salesReprocessAxios.post(`/reprocess`, data);
  }
}
