import { eachDayOfInterval, format } from 'date-fns';
import { closingSort } from '../../helpers';
import {
  Closing,
  ClosingDetailGroup,
  ClosingViewMode,
  GcsFilter,
  MissingCauses,
  RemoteResource,
  ReportFilter,
} from '../../types';

export const getFilterApplied = <T extends keyof ReportFilter | keyof GcsFilter>(
  filters: T[],
  key: T,
  value: unknown
): T[] => {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return filters.filter(i => i !== key);
  }

  if (filters.includes(key)) {
    return filters;
  }

  return [...filters, key];
};

const updateCauses = (mainCauses: string[], secondaryCauses: string[]): string[] => {
  const causes = [...new Set([...mainCauses, ...secondaryCauses])];

  const causesToRemove = [
    MissingCauses.SALES_CLOSING_NOT_INFORMED,
    MissingCauses.COLL_CLOSING_NOT_INFORMED,
  ];

  const hasBothClosings = causesToRemove.every(cause => causes.includes(cause));

  if (hasBothClosings) {
    return [
      ...causes.filter(cause => !causesToRemove.includes(cause as MissingCauses)),
      MissingCauses.CLOSING_NOT_INFORMED,
    ];
  }

  return causes;
};

const joinClosings = (
  mainData: Closing[],
  secondaryResource: RemoteResource<Closing[]>
): Closing[] => {
  if (secondaryResource.loaded === true) {
    const secondaryData = secondaryResource.data;

    const mergedClosings = mainData.reduce((result: Closing[], mainItem) => {
      const matchingItem = secondaryData.find(
        secondaryItem =>
          secondaryItem.country === mainItem.country &&
          secondaryItem.store === mainItem.store &&
          secondaryItem.date.toISOString() === mainItem.date.toISOString()
      );

      result.push({
        ...mainItem,
        cause: updateCauses(mainItem.cause, matchingItem?.cause || []),
      });

      return result;
    }, []);

    const unmatchedSecondary = secondaryData
      .filter(
        secondaryItem =>
          !mainData.some(
            mainItem =>
              mainItem.country === secondaryItem.country &&
              mainItem.store === secondaryItem.store &&
              mainItem.date.toISOString() === secondaryItem.date.toISOString()
          )
      )
      .map(secondaryItem => ({
        ...secondaryItem,
        cause: updateCauses([], secondaryItem.cause),
      }));

    return [...mergedClosings, ...unmatchedSecondary];
  }

  return [];
};

export const generateCurrentClosing = (
  mainResource: Closing[],
  startDate: Date | null,
  endDate: Date | null,
  viewMode: ClosingViewMode,
  secondaryResource: RemoteResource<Closing[]>
) => {
  const detail = (
    viewMode === ClosingViewMode.CONSOLIDATED
      ? joinClosings(mainResource, secondaryResource)
      : mainResource
  ).sort((a, b) => a.date.getTime() - b.date.getTime());

  const dates = eachDayOfInterval({
    start: startDate as Date,
    end: endDate as Date,
  });

  const dataGroup: Record<string, ClosingDetailGroup> = {};

  detail.forEach(closing => {
    closing.cause.forEach(cause => {
      const key = `${cause}${closing.country}${closing.store}`;

      if (!dataGroup[key]) {
        dataGroup[key] = {
          ...closing,
          cause,
          quantity: 0,
          dates: [],
        };
      }

      dataGroup[key].dates.push(closing.date);
      dataGroup[key].quantity += 1;
    });
  });

  const missingClosingDetailGroup = Object.values(dataGroup);

  dates.forEach((date, index) => {
    const formattedDate = format(date, 'yyyy-MM-dd');

    missingClosingDetailGroup.forEach(group => {
      const formattedGroupDate = group.dates[index]
        ? format(group.dates[index], 'yyyy-MM-dd')
        : undefined;

      if (formattedGroupDate !== formattedDate) {
        group.dates.splice(index, 0, undefined);
      }
    });
  });

  missingClosingDetailGroup.forEach(i => i.dates.reverse());

  return missingClosingDetailGroup.sort(closingSort);
};
