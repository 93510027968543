import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CategorySelectFormInput, { CategorySelectFormInputProps } from './CategorySelectFormInput';
import useReprocessTypeSelect from '../../hooks/useReprocessTypeSelect';
import { ReprocessTypeCategory } from '../../types';

export type ReprocessTypeFormInputProps = Omit<
  CategorySelectFormInputProps<ReprocessTypeCategory>,
  'label' | 'options' | 'value' | 'validation'
>;

const ReprocessTypeFormInput = (props: ReprocessTypeFormInputProps) => {
  const { t } = useTranslation();
  const { categoryOptions, loading } = useReprocessTypeSelect();
  const getOptionLabel = useCallback(
    (option: ReprocessTypeCategory) =>
      option ? t(`reprocess.type.${typeof option === 'string' ? option : option.type}`) : '',
    [t]
  );

  const firstNoneCategory = (arr: ReprocessTypeCategory[]): ReprocessTypeCategory[] =>
    arr.sort((a, b) => {
      const categorya = a.category === 'none';
      const categoryb = b.category === 'none';

      if (!categorya && categoryb) return 1;
      if (categorya && !categoryb) return -1;

      return b.category.localeCompare(a.category);
    });
  return (
    <CategorySelectFormInput<ReprocessTypeCategory>
      {...props}
      label={t('reprocess.labels.type')}
      options={firstNoneCategory(categoryOptions)}
      groupBy={option =>
        option.category === 'none' ? '' : t(`reprocess.category.${option.category}`)
      }
      getOptionLabel={getOptionLabel}
      fieldValue="type"
      disableClearable
      loading={loading}
    />
  );
};

export default ReprocessTypeFormInput;
