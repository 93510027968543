import { useSelector } from 'react-redux';
import MultiSelect, { MultiSelectProps } from '../MultiSelect';
import { masterDataSelector } from '../../../state';

export type FederativeEntityMultiSelectProps = Omit<
  MultiSelectProps<string>,
  'options' | 'propDesc'
>;

const FederativeEntityMultiSelect = ({
  translationKey,
  onChange,
  value,
  selectAll,
  disabled,
  ...others
}: FederativeEntityMultiSelectProps) => {
  const masterData = useSelector(masterDataSelector);

  const federativeEntities =
    masterData.loaded && masterData.data ? masterData.data.federativeEntities : [];
  const loading = masterData.loading && !masterData.loaded;

  return (
    <MultiSelect
      {...others}
      options={federativeEntities}
      disabled={disabled}
      value={value}
      loading={loading}
      onChange={onChange}
      selectAll={selectAll}
    />
  );
};

export default FederativeEntityMultiSelect;
