import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import isInt from 'validator/es/lib/isInt';

import { changeFilterPlu, reportFilterPluSelector } from '../../../state';

const PluFilter = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const pluStateValue = useSelector(reportFilterPluSelector);
  const [plu, setPlu] = useState(pluStateValue.join(','));

  const handleChange: NonNullable<TextFieldProps['onChange']> = useCallback(
    event => {
      const min = 1;
      const max = 999999;
      const { value } = event.target;
      const values = (value || '')
        .split(',')
        .map(i => i.trim())
        .filter(i => i);
      if (values.length && values.some(i => !isInt(i, { min, max }))) {
        if (!error) setError(t('validations.listIntegerRange', { min, max }));
        dispatch(changeFilterPlu([]));
      } else {
        if (error) setError(undefined);
        dispatch(changeFilterPlu(values.map(Number)));
      }

      setPlu(value);
    },
    [dispatch, setPlu, error, setError, t]
  );

  return (
    <TextField
      onChange={handleChange}
      variant="standard"
      value={plu}
      label={t('table.columns.plu')}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export default PluFilter;
