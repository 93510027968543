import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import { startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { isEqualNullableDate } from '../../../helpers';
import { DateValue } from '../../../types';
import { DataGridFilters } from '../DataGridHandler/DataGridHandler';

interface CurrentDateWarningProps {
  filters: DataGridFilters;
}

const isCurrentDateSelected = (filters: NonNullable<DataGridFilters>) => {
  const today = startOfDay(new Date());
  const dateKeys = [
    'startBusinessDate',
    'endBusinessDate',
    'startOperationDate',
    'endOperationDate',
    'startAccountingDate',
    'endAccountingDate',
  ];

  return dateKeys.some(key => {
    if (key in filters) {
      const dateValue = (filters[key as keyof typeof filters] as DateValue)?.raw as
        | Date
        | undefined;
      return dateValue ? isEqualNullableDate(today, dateValue) : false;
    }
    return false;
  });
};

const CurrentDateWarning = ({ filters }: CurrentDateWarningProps) => {
  const { t } = useTranslation();
  return filters && isCurrentDateSelected(filters) ? (
    <Tooltip title={t('dataGridFlexSales.warnings.currentDate')} placement="right">
      <WarningIcon color="warning" />
    </Tooltip>
  ) : null;
};

export default CurrentDateWarning;
