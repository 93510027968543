import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  reportCollectionsFilterSelector,
  reportFilterCountryCodeSelector,
  reportPettyCashDifferenceSelector,
} from '../../state';
import { PettyCashDifference } from '../../types';
import handlerFactory, { PettyCashDifferenceDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<
    PettyCashDifference,
    PettyCashDifferenceDataGridContext
  >();

  const { t } = useTranslation();
  const title = t('petty-cash-difference.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const pettyCashDifferenceResource = useSelector(reportPettyCashDifferenceSelector);
  const reportCollectionsFilters = useSelector(reportCollectionsFilterSelector);

  const { data, loading } = pettyCashDifferenceResource;

  const gridContext = useMemo(
    () => ({
      t,
      country,
    }),
    [t, country]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);
  handler.setFilters(reportCollectionsFilters);

  return null;
};

const PettyCashDifferenceDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default PettyCashDifferenceDataGrid;
