import { addDays, setDate, subDays } from 'date-fns';

const getDashboardIncorporationDates = () => {
  const today = new Date();
  const yesterday = addDays(new Date(), -1);
  const lastMonthTo = new Date(yesterday);
  lastMonthTo.setDate(0);
  const lastMonthFrom = new Date(yesterday);
  lastMonthFrom.setDate(1);
  lastMonthFrom.setMonth(lastMonthFrom.getMonth() - 1);
  return {
    today,
    yesterday,
    currentMonthFrom: setDate(yesterday, 1),
    lastMonthFrom,
    lastMonthTo,
  };
};

export default class {
  static reportFilterRangeMaxDate = subDays(new Date(), 1);

  static reportGcsFilterRangeMaxDate = new Date();

  static reportCollectionsFilterRangeMaxDate = new Date();

  static reportCancellationsFilterRangeMaxDate = new Date();

  static reportThirdPartySalesByItemsFilterRangeMaxDate = new Date();

  static masterImportRangeMaxDate = new Date();

  static dashboardIncorporationDates = getDashboardIncorporationDates();

  // default value in minutes
  static defaultDashboardTimer = 10;
}
