import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePendingInterfacesFilterStore,
  reportPendingInterfacesFilterCountrySelector,
  reportPendingInterfacesFilterStoresSelector,
  reportOpStructuresFilterAgreementSelector,
  reportOpStructuresFilterCompanySelector,
  reportOpStructuresFilterManagementSelector,
  reportOpStructuresFilterRegionSelector,
  reportOpStructuresFilterRegionalManagementSelector,
  reportOpStructuresFilterSupervisionSelector,
} from '../../../state';
import { Stores } from '../../../types';
import { StoreSelect } from '../../selects';

const StoreFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const country = useSelector(reportPendingInterfacesFilterCountrySelector);
  const storesSelected = useSelector(reportPendingInterfacesFilterStoresSelector);
  const regionalManagement = useSelector(reportOpStructuresFilterRegionalManagementSelector);
  const regions = useSelector(reportOpStructuresFilterRegionSelector);
  const managements = useSelector(reportOpStructuresFilterManagementSelector);
  const supervisions = useSelector(reportOpStructuresFilterSupervisionSelector);
  const agreements = useSelector(reportOpStructuresFilterAgreementSelector);
  const companies = useSelector(reportOpStructuresFilterCompanySelector);

  const handleChange = useCallback(
    (value: Stores[]) => dispatch(changePendingInterfacesFilterStore(value)),
    [dispatch]
  );

  return (
    <StoreSelect
      label={t('toolbar.labels.store')}
      limitTags={3}
      countryCode={country}
      value={storesSelected}
      onChange={handleChange}
      regionalManagements={regionalManagement}
      regions={regions}
      managements={managements}
      supervisions={supervisions}
      agreements={agreements}
      companies={companies}
      selectAll
    />
  );
};

export default StoreFilter;
