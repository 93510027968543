import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { MultiSelect, MultiSelectProps } from '../../selects';
import { ClosingFranchiseType } from '../../../types';
import {
  changeClosingFilterFranchiseTypes,
  reportClosingFilterFranchiseTypeSelector,
} from '../../../state';

type FranchiseTypeFilterProps = Pick<MultiSelectProps<string>, 'fullWidth'>;

const FranchiseTypeFilter = (props: FranchiseTypeFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const franchiseTypes = useSelector(reportClosingFilterFranchiseTypeSelector);
  const options = Object.values(ClosingFranchiseType);

  const handleChange = useCallback(
    (value: string[]) =>
      dispatch(changeClosingFilterFranchiseTypes(value as ClosingFranchiseType[])),
    [dispatch]
  );

  return (
    <MultiSelect<string>
      {...props}
      options={options}
      value={franchiseTypes}
      translationKey="operativeStructure.franchiseType"
      label={t('operativeStructure.franchiseType.label')}
      onChange={handleChange}
      selectAll
    />
  );
};

export default FranchiseTypeFilter;
